import moment from 'moment';
import React from 'react';
import { DocumentIndex } from '../../types/meta';

interface GenerateQueryParams {
  indexes?: DocumentIndex[];
  indexValues?: Record<string, string | undefined>;
  createdStartDate?: Date;
  createdEndDate?: Date;
  modifiedStartDate?: Date;
  modifiedEndDate?: Date;
  selectedTypes?: string[];
  freeTextSearch?: string;
  statusSearch?: string;
}

export const generateDateRange = (fieldName: string, startDate?: Date, endDate?: Date) => {
  if (startDate) {
    startDate.setUTCHours(0, 0, 0, 0);
  }
  if (endDate) {
    endDate.setUTCHours(23, 59, 59, 999);
  }
  const startDateVal = startDate ? startDate.toISOString() : '*';
  const endDateVal = endDate ? endDate.toISOString() : '*';

  return `${fieldName}:[${startDateVal} TO ${endDateVal}]`;
};

export const generateIndexQueryValues = (indexes: DocumentIndex[], indexValues: Record<string, string | undefined>) => {
  const toReturn: string[] = [];
  for (const index of indexes) {
    let value = indexValues[index.id];
    if (!value) continue;
    if (index.type === 'date') {
      console.log('date value', value);
      const dateValue = new Date(value);
      if (dateValue) {
        dateValue.setUTCHours(0, 0, 0, 0);
        value = dateValue.toISOString();
      }
      toReturn.push(`indexes.${index.id}:"${value}"`);
    } else if (index.type === 'string') {
      toReturn.push(`(indexes.${index.id}:"${value}"^4 OR indexes.${index.id}:*${value}*^3)`);
    } else {
      toReturn.push(`indexes.${index.id}:${value}`);
    }
  }
  return toReturn;
};

export const generateTypeQuery = (types: string[]) => {
  const queryValues: string[] = [];
  for (const type of types) {
    queryValues.push(`types:${type}`);
  }

  return `(${queryValues.join(' OR ')})`;
};

export const generateStatusQuery = (status: string) => {
  if (status === '*') return '(status:"ACTIVE" OR status:"INACTIVE")';
  return `status:"${status}"`;
};

const generateFreeTextQuery = (indexes: DocumentIndex[], freeTextSearch: string) => {
  const queryValues = [];
  for (const index of indexes) {
    if (index.type === 'date') {
      try {
        const value = `"${moment(freeTextSearch).toISOString()}"`;
        queryValues.push(`indexes.${index.id}:${value}^4`);
      } catch (e) {
        console.log('unable to parse free search as date... skipping index for search');
      }
    } else if (index.type === 'string') {
      queryValues.push(`(indexes.${index.id}:"${freeTextSearch}"^4 OR indexes.${index.id}:*${freeTextSearch}*^3)`);
    } else {
      queryValues.push(`indexes.${index.id}:${freeTextSearch}^4`);
    }
  }

  queryValues.push(`pdfText:"*${freeTextSearch}*"`);
  return `(${queryValues.join(' OR ')})`;
};
//"indexes." + idKey + ": \"" + studentId + "\""
export const generateQuery = ({
  indexes,
  indexValues,
  createdStartDate,
  createdEndDate,
  modifiedStartDate,
  modifiedEndDate,
  selectedTypes,
  freeTextSearch,
  statusSearch,
}: GenerateQueryParams): string => {
  let queryValues: string[] = [];
  if (indexes && indexValues) {
    queryValues = queryValues.concat(generateIndexQueryValues(indexes, indexValues));
  }

  if (createdStartDate || createdEndDate) {
    queryValues.push(generateDateRange('created', createdStartDate, createdEndDate));
  }

  if (modifiedStartDate || modifiedEndDate) {
    queryValues.push(generateDateRange('modified', modifiedStartDate, modifiedEndDate));
  }

  if (selectedTypes && selectedTypes.length > 0) {
    queryValues.push(generateTypeQuery(selectedTypes));
  }

  if (statusSearch) {
    queryValues.push(generateStatusQuery(statusSearch));
  }

  if (freeTextSearch && indexes) {
    queryValues.push(generateFreeTextQuery(indexes, freeTextSearch));
  }
  console.log('the query is: ' + queryValues.join(' AND '));
  return queryValues.join(' AND ');
};
